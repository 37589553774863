// /*global fbq*/

import React from 'react';

import {useIntl} from 'react-intl';
import styled from 'styled-components';

const EmailInput = styled.input`
  width: 275px;
  padding: 0.5em 1em;
  height: 50px;
  border-radius: 10px 0 0 10px;
  border: solid 1px #979797;
  outline: none;

  @media screen and (max-width: 450px) {
    width: 90%;
    border-radius: 10px 10px;
  }
`;

const ButtonInput = styled.input<{
  btnBackgroundColor: string;
  btnTextColor: string;
  btnHoverBackgroundColor: string;
}>`
  padding: 0 1em;
  height: 50px;
  border: 0;
  border-radius: 0 10px 10px 0;
  background-color: ${(props) => props.btnBackgroundColor};
  color: ${(props) => props.btnTextColor};
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      props.btnHoverBackgroundColor || props.btnBackgroundColor};
  }

  @media screen and (max-width: 450px) {
    width: 50%;
    margin-top: 25px;
    border-radius: 10px 10px;
  }
`;

interface NewsLetterSignUpProps {
  listURL: string;
  submitText?: string;
  btnBackgroundColor: string;
  btnHoverBackgroundColor: string;
  btnTextColor: string;
}

const NewsletterSignUp: React.FC<NewsLetterSignUpProps> = (props) => {
  const {
    listURL,
    submitText,
    btnBackgroundColor,
    btnHoverBackgroundColor,
    btnTextColor,
  } = props;

  const intl = useIntl();

  const handleSubmit = (): void => {
    //fbq('track', 'Lead');
  };

  return (
    <form
      action={listURL}
      method="post"
      id="mc-embedded-subscribe-form1"
      name="mc-embedded-subscribe-form"
      className="validate"
      target="_blank"
      style={{width: '100%'}}
      onSubmit={handleSubmit}
    >
      <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
        <input
          type="text"
          name="b_082dda916c00d472b4e61d119_70bc062aad"
          tabIndex={-1}
        />
      </div>
      <div className="input-row">
        <EmailInput
          aria-label="Email"
          type="email"
          name="EMAIL"
          id="mce-EMAIL"
          placeholder={intl.formatMessage({
            id: 'NewsletterSignUp.inputPlaceholder',
            defaultMessage: 'Enter your email',
          })}
        />
        <ButtonInput
          type="submit"
          value={
            submitText ||
            intl.formatMessage({
              id: 'NewsletterSignUp.defaultButtonText',
              defaultMessage: 'Sign Up',
            })
          }
          name="subscribe"
          id="mc-embedded-subscribe"
          btnHoverBackgroundColor={btnHoverBackgroundColor}
          btnBackgroundColor={btnBackgroundColor}
          btnTextColor={btnTextColor}
        />
      </div>
    </form>
  );
};

export default NewsletterSignUp;
